/* FIXME: Standardize this into reusable styles. The same styles are used in BeamTextField and other input fields.*/
.formInputLabel {
  color: var(--beam-color--sky-800);
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
  line-height: 1;
}
