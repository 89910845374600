/* Dropdown form element */
.beam--dropdown::part(base) {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 700;
  font-size: var(--beam--font-size-x-small);
}

.beam--dropdown::part(form-control-label) {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 500;
  font-size: var(--beam--font-size-medium);
  line-height: 40px;
  color: var(--beam-color--sky-800);
}

/* Asterisk on required fields */
.beam--dropdown::part(form-control-label)::after {
  color: var(--beam-color--cherry-600);
}

.beam--dropdown::part(display-input) {
  border-radius: var(--beam--border-radius);
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}

.beam--dropdown::part(button) {
  color: blue;
  background-color: blueviolet;
}

/* Dropdown multiselect tags */

.beam--dropdown::part(tag__base) {
  font-size: var(--beam--font-size-x-small);
  font-weight: var(--beam--font-weight-bold);
}

/* Dropdown options */

.beam--dropdown--option::part(base) {
  color: var(--beam-color--charcoal-600);
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}

.beam--dropdown--option[aria-selected='false']::part(base) {
  background-color: var(--beam-color--white);
}

.beam--dropdown--option::part(base):hover,
.beam--dropdown--option::part(base):focus,
.beam--dropdown--option::part(base):active {
  background-color: var(--beam-color--sky-50);
}

/* Dropdown options - single select */

.beam--dropdown--option.single[aria-selected='true']::part(base) {
  background-color: var(--beam-color--sky-50);
}

.beam--dropdown--option.single::part(base) {
  padding: 10px 20px;
}

.beam--dropdown--option.single::part(prefix),
.beam--dropdown--option.single::part(checked-icon) {
  display: none;
}

/* Dropdown options - multiselect */

.beam--dropdown--option.multiple::part(base) {
  padding: 10px 20px;
  transition: background-color 0.1s;
}

.beam--dropdown--option.multiple[aria-selected='true']::part(base) {
  background-color: var(--beam-color--white);
}

.beam--dropdown--option.multiple[aria-selected='true']::part(base):hover {
  background-color: var(--beam-color--lime-500);
}

.beam--dropdown--option.multiple::part(checked-icon) {
  display: none;
}

.beam--dropdown--option-checkbox {
  background-color: var(--beam-color--sun);
  border: 1px solid var(--beam-color--charcoal-200);
  border-radius: 4px;
  width: 22px;
  height: 22px;
  margin-left: -8px;
  margin-right: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s, border-color 0.1s;
}

.beam--dropdown--option-checkbox-icon {
  opacity: 0;
  transition: 0.1s;
}

[aria-selected='true'] .beam--dropdown--option-checkbox-icon {
  opacity: 1;
}

.beam--dropdown--option.multiple:hover .beam--dropdown--option-checkbox-icon {
  opacity: 0.33;
}

.beam--dropdown--option.multiple:hover .beam--dropdown--option-checkbox {
  background-color: var(--beam-color--sun-500);
  border-color: var(--beam-color--charcoal-200);
}
