div.beam--textfield--base,
sl-input.beam--textfield::part(base) {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--beam-color--charcoal-200);
}

div.beam--textfield--base,
input.beam--textfield {
  border-radius: var(--sl-border-radius-medium);
  padding: 6px 8px;
}

input.beam--textfield::placeholder,
sl-input.beam--textfield.beam--textfield--large::part(input)::placeholder {
  color: var(--beam-color--navy);
}

input.beam--textfield.beam--textfield--large,
sl-input.beam--textfield.beam--textfield--large::part(input) {
  font-family: var(--beam--font-family-paragraph);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--beam-color--navy);
}

input.beam--textfield.beam--textfield--small,
sl-input.beam--textfield.beam--textfield--small::part(input) {
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: var(--beam-color--grey);
}

sl-input.beam--textfield::part(form-control-label) {
  color: var(--beam-color--sky-800);
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
  line-height: 40px;
}

sl-input.beam--textfield--empty-label::part(form-control-label) {
  display: none;
}

sl-input.beam--textfield::part(form-control-help-text) {
  margin-top: 2px;
  font-size: 12px;
  font-weight: 900;
  font-family: var(--beam--font-family-base);
  line-height: normal;
  color: var(--beam-color--charcoal-500);
}

sl-input.beam--textfield.beam--textfield--small.email-error::part(input) {
  border: 1px solid var(--beam-color--error);
}

sl-input.beam--textfield.email-error::part(form-control-help-text) {
  color: var(--beam-color--error);
  font-size: 11px;
  margin-top: 5px !important;
}

/* Asterisk on required fields */
sl-input.beam--textfield::part(form-control-label)::after {
  color: var(--beam-color--cherry-600);
}
