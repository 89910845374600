.progressBar {
  display: block;
}

.title {
  font-family: var(--beam--font-family-base);
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
  color: var(--beam-color--charcoal-800);
}

.progressBarContainer {
  display: flex;
  align-items: center;
}

.progressBar {
  flex: 1;
}

.percentageLabel {
  font-family: var(--beam--font-family-paragraph);
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  line-height: 14px;
  width: 30px;
}

.description {
  font-family: var(--beam--font-family-base);
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: var(--beam-color--charcoal-500);
}

.completions {
  font-family: var(--beam--font-family-base);
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  color: var(--beam-color--charcoal-500);
  float: right;
}

.progressBar::part(base) {
  background: var(--beam-color--light-grey);
  height: 8px;
}

.progressBar::part(indicator) {
  position: relative;
  height: 100%;
  border-radius: 8px;
  mask: linear-gradient(var(--beam-color--light-grey) 0 0);
  overflow: hidden;
}

.progressBar::part(indicator)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    75.68deg,
    var(--beam-color--water-500) 0.08%,
    var(--beam-color--sun-500) 45.23%,
    var(--beam-color--cherry-500) 84.24%
  );
}
