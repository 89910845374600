.subheading {
  font-family: var(--beam--font-family-paragraph);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--beam-color--light-grey-1);
  text-align: center;
  padding-top: 7px;
}

.closeIcon {
  position: absolute;
  top: 9px;
  right: 8px;
  z-index: 0;
}

:global(sl-dialog.beam--modal::part(panel)) {
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-color: var(--beam-color--charcoal-800);
  box-shadow: 4px 6px 0px var(--beam-color--modal-overlay);
}

:global(sl-dialog.beam--modal::part(title)) {
  font-weight: 900;
  font-size: 30px;
  line-height: 28px;
  font-family: var(--beam--font-family-base);
  font-style: normal;
  text-align: center;
  padding-bottom: 8px;
  padding-top: 0px;
  text-align: center;
  color: var(--beam-color--charcoal-800);
}

:global(sl-dialog.beam--modal--no-title::part(title)) {
  display: none;
}

:global(sl-dialog.beam--modal::part(header)) {
  flex-direction: column-reverse;
}

:global(sl-dialog.beam--modal.beam--empty-header-title::part(title)) {
  display: none;
}

:global(sl-dialog.beam--modal::part(header-actions)) {
  @apply px-2 py-2;
}

:global(sl-dialog.beam--modal::part(close-button)) {
}

:global(sl-dialog.beam--modal::part(close-button__base)) {
  color: var(--beam-color--black);
}

:global(sl-dialog.beam--modal::part(body)) {
  padding: 0px 16px 5px 16px;
}

:global(sl-dialog.beam--modal::part(overlay)) {
  background: var(--beam-color--modal-overlay);
  opacity: 50%;
}

:global(sl-dialog.beam--modal.show-footer-shadow::part(footer)) {
  padding: 0px 14px 27px 14px;
  z-index: 100;
}
:global(sl-dialog.beam--modal.show-footer-shadow::part(footer)) {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 960px) {
  :global(sl-dialog.beam--modal::part(body)) {
    padding: 0px 30px 0px 30px;
  }

  :global(sl-dialog.beam--modal::part(footer)) {
    padding: 0px 30px 25px 30px;
  }
}

:root {
  --beam-color--modal-overlay: #8daddc;
}
