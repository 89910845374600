.progressContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.progressBase {
  width: 100%;
  height: 8px;
  background-color: var(--beam-color--light-grey);
  border-radius: 5px;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  background-image: linear-gradient(
    76deg,
    var(--beam-color--water-500),
    var(--beam-color--sun-500) 25%,
    var(--beam-color--cherry-500) 50%,
    var(--beam-color--water-500) 90%
  );
  background-size: 200% 200%;
  animation: gradientAnimation 2s linear infinite;
  transition: width 0.5s ease-in-out;
}

.progressBar.noAnimation {
  animation: none;
}

.percentageLabel {
  font-family: var(--beam--font-family-paragraph);
  font-size: var(--beam--font-size-x-small);
  font-weight: var(--beam--font-weight-normal);
  text-align: right;
  line-height: 1;
  padding-left: 8px;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: -100% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
