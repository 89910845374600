.container {
  padding: 0 10px;
  margin-bottom: 50px;
}

.modalTitle {
  font-family: var(--beam--font-family-base) !important;
  font-size: var(--beam--font-size-2x-large);
  font-weight: var(--beam--font-weight-black);
  line-height: 36px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: capitalize;
  color: var(--beam-color--charcoal-800) !important;
  margin: 0;
}

.modalDescription {
  font-family: var(--beam--font-family-paragraph) !important;
  font-size: var(--beam--font-size-small);
  font-weight: var(--beam--font-weight-normal);
  line-height: 20px;
  text-align: center;
  color: var(--beam-color--charcoal-800);
  padding-bottom: 20px;
  padding-top: 10px;
  letter-spacing: 0.4px;
}

.instructions {
  font-family: var(--beam--font-family-base) !important;
  font-size: var(--beam--font-size-medium);
  font-weight: var(--beam--font-weight-black);
  line-height: 32px;
  letter-spacing: 0.004em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--beam-color--sky-800) !important;
}

.list {
  list-style-type: disc;
  margin-left: 22px;
}

.marketingVaultButton {
  width: min-content;
}

.fileUpload {
  font-family: var(--beam--font-family-paragraph);
  font-size: var(--beam--font-size-small);
  font-weight: var(--beam--font-weight-normal);
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  flex-grow: 1;
  color: var(--beam-color--sky-800);
}

.listOfNonprofits {
  margin: 10px 0 10px 10px;
}

.nonprofitName {
  white-space: nowrap;
  margin-right: 5px;
  line-height: 32px;
}

.expandableGuidelines {
  padding-top: 20px;
}

:global(.beam--button--text::part(base)) {
  text-decoration: none !important;
}

.footer {
  padding: 0 10px;
  margin-bottom: 20px;
}
