.campaignDetailsHeader {
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-medium);
  color: var(--beam-color--charcoal-800);
  font-weight: var(--beam--font-weight-bold);
}

.campaignDetailsDatum {
  font-family: var(--beam--font-family-paragraph);
  font-size: var(--beam--font-size-small);
}

.campaignParagraph {
  font-family: var(--beam--font-family-paragraph);
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
}

sl-select.campaignDropdown::part(combobox) {
  background-color: var(--beam-color--sun);
  border-color: var(--beam-color--sun);
  color: var(--beam-color--sky-800);
  font-size: 14px !important;
}

sl-select.campaignDropdown[disabled]::part(combobox) {
  background-color: var(--beam-color--charcoal-100);
  border-color: var(--beam-color--charcoal-100);
  color: var(--beam-color--charcoal-500);
  font-size: 14px !important;
}

sl-select.campaignDropdown::part(form-control-input) {
  --sl-input-placeholder-color: var(--beam-color--sky-800);
  font-size: 14px;
  font-weight: 500;
}

sl-select.campaignDropdown[disabled]::part(form-control-input) {
  --sl-input-placeholder-color: var(--beam-color--charcoal-500);
}
