.fileUploadContainer {
  width: 100%;
  position: relative;
}

.fileUploadButton,
.clearButton {
  position: absolute;
  right: 0;
}

.fileUploadButton {
  z-index: 0;
  margin: 0px 3px 4px 0px;
}
.clearButton {
  z-index: 2;
}

.uploadIconLabel {
  cursor: pointer;
}

.fileUploadWidget {
  display: none;
}

.existingFilename {
  position: relative;
  text-decoration: underline;
  line-height: 32px;
  display: inline;
  white-space: nowrap;
  z-index: 0;
  color: var(--beam-color--sky-800) !important;
}

.fileUploadWidget::file-selector-button {
  display: none;
}
