.beamDropzone {
  /* Layout & positioning */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 8px 12px 24px 12px;
  border: 2px dashed var(--beam-color--sky-800);
  border-radius: var(--beam--border-radius);

  /* Appearance */
  cursor: pointer;
  background-color: var(--beam-color--white);
  color: var(--beam-color--sky-800);
  transition: background-color 0.2s ease-in-out;

  /* Typography */
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-x-small);
  font-weight: var(--beam--font-weight-medium);

  /* Drag states */
  &.dragAccept {
    background-color: var(--beam-color--sky-100);
  }

  &.dragReject {
    background-color: var(--beam-color--cherry-100);
    border-color: var(--beam-color--cherry-800);
    color: var(--beam-color--cherry-800);
  }

  &.hideDropzone {
    display: none;
  }
}

/* Placeholder Content Wrapper */
.placeholderContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.placeholderText {
  color: inherit;
  font: inherit;
}

/* File List */

.fileList {
  margin-top: 16px;
}
.fileList > .filePill:not(:first-child) {
  margin-top: 8px;
}
.filePill {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 6px;

  border: solid 1px var(--beam-color--charcoal-100);
  border-radius: var(--beam--border-radius);
  color: var(--beam-color--sky-800);

  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-x-small);
  font-weight: var(--beam--font-weight-medium);

  .fileRemoveButton {
    font-size: 16px;
    margin-left: auto;
    color: inherit;
  }

  &.fileError {
    border-color: var(--beam-color--cherry-800);
    color: var(--beam-color--cherry-800);
  }
}

.errorList {
  font-family: var(--beam--font-family-base);
  font-size: var(--beam--font-size-x-small);
  font-weight: var(--beam--font-weight-medium);
  color: var(--beam-color--cherry-800);

  list-style-type: disc;
  list-style-position: inside;

  margin-top: 8px;

  li {
    display: list-item;
  }
}
