.beam--toast {
  margin: 40px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  /* z-index used to appear over the modal: */
  z-index: 1000;
}

sl-alert.beam--toast.beam--toast--success::part(base) {
  background-color: var(--beam-color--moss-50);
  border-color: var(--beam-color--moss-50);
  color: var(--beam-color--moss-800);
  border-radius: 8px;
}
sl-alert.beam--toast.beam--toast--success::part(icon) {
  margin-right: 50px;
}

sl-alert.beam--toast.beam--toast--error::part(base) {
  background-color: var(--beam-color--cherry-100);
  border-top-color: var(--beam-color--cherry-100);
  color: var(--beam-color--cherry-800);
  border-radius: 8px;
}

sl-alert.beam--toast::part(close-button__base) {
  color: var(--beam-color--black);
}

.beam--toast--icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.beam--toast--content {
  display: flex;
  width: 300px;
  flex-direction: row;
  font-family: var(--beam--font-family-base);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  align-items: center;
}

.beam--toast--content div {
  /* This whole thing is an override of `index.css` */
  font-family: var(--beam--font-family-base) !important;
}

@media only screen and (max-width: 960px) {
  .beam--toast {
    margin: 10px;
    left: 0;
    right: 0;
    display: inline-block;
  }

  .beam--toast--content {
    width: 100%;
    flex-shrink: 0;
  }
}
